import { isJpmCountry } from "@/helpers/constants";
import { CountryCode, IUser, Routes, TRequestPermissionLevels, TResponsePermissionLevels } from "@/types";

export const getRequestPermissions = (user?: IUser) =>
  (user?.userAttributes
    ?.filter(v => v.type === "admin_request_permission")
    ?.map(v => v.value) as TRequestPermissionLevels[]) || [];
export const getResponsePermissions = (user?: IUser) =>
  (user?.userAttributes
    ?.filter(v => v.type === "admin_response_permission")
    ?.map(v => v.value) as TResponsePermissionLevels[]) || [];

export const permissionsByPage = (
  country: CountryCode,
  hideRequestService: boolean,
  hideAccounts: boolean,
  isAlertEnabled: boolean,
  hideParticipantsCache: boolean,
  user?: IUser,
) => {
  const reqPermissions = getRequestPermissions(user);
  const resPermissions = getResponsePermissions(user);

  // ----- Dashboard -----
  const allowedDashboardCountries = !(
    country === CountryCode.NL ||
    country === CountryCode.FR ||
    country === CountryCode.EPC
  );
  const allowedDashboardReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("Transaction History") ||
    reqPermissions.includes("ITSM") ||
    reqPermissions.includes("Participant Management");
  const allowedDashboardRes =
    resPermissions.includes("Administration") ||
    resPermissions.includes("Transaction History") ||
    resPermissions.includes("ITSM") ||
    resPermissions.includes("Participant Management");
  const allowedDashboardOther = true;
  const dashboardAllowed = {
    countries: allowedDashboardCountries,
    reqPermissions: allowedDashboardReq,
    resPermissions: allowedDashboardRes,
    other: allowedDashboardOther,
    isAllowed: allowedDashboardCountries && (allowedDashboardReq || allowedDashboardRes) && allowedDashboardOther,
  };
  // ----- Dashboard -----

  // ----- RequestPayee -----
  const allowedReqPayeeCountries = country !== CountryCode.EPC;
  const allowedReqPayeeReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("CoP Check");
  const allowedReqPayeeRes = false;
  const allowedReqPayeeOther = !hideRequestService;
  const requestPayeeAllowed = {
    countries: allowedReqPayeeCountries,
    reqPermissions: allowedReqPayeeReq,
    resPermissions: allowedReqPayeeRes,
    other: allowedReqPayeeOther,
    isAllowed: allowedReqPayeeCountries && allowedReqPayeeReq && allowedReqPayeeOther,
  };
  // ----- RequestPayee -----

  // ----- Req Transaction History -----
  const allowedReqTHCountries = country !== CountryCode.EPC;
  const allowedReqTHReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("Transaction History");
  const allowedReqTHRes = false;
  const allowedReqTHOther = !hideRequestService;
  const reqTHAllowed = {
    countries: allowedReqTHCountries,
    reqPermissions: allowedReqTHReq,
    resPermissions: allowedReqTHRes,
    other: allowedReqTHOther,
    isAllowed: allowedReqTHCountries && allowedReqTHReq && allowedReqTHOther,
  };
  // ----- Req Transaction History -----

  // ----- Req MI Report -----
  const allowedReqMIReportCountries = country === CountryCode.UK;
  const allowedReqMIReportReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("MI Report Access");
  const allowedReqMIReportRes = false;
  const allowedReqMIReportOther = !hideRequestService;
  const reqMIReportAllowed = {
    countries: allowedReqMIReportCountries,
    reqPermissions: allowedReqMIReportReq,
    resPermissions: allowedReqMIReportRes,
    other: allowedReqMIReportOther,
    isAllowed: allowedReqMIReportCountries && allowedReqMIReportReq && allowedReqMIReportOther,
  };
  // ----- Req MI Report -----

  // ----- Req MI Report(GLOBAL) -----
  const allowedReqMIReportCountriesGlobal = country === CountryCode.GLOBAL;
  const allowedReqMIReportReqGlobal =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("MI Report Access");
  const allowedReqMIReportResGlobal = false;
  const allowedReqMIReportOtherGlobal = !hideRequestService;
  const reqMIReportAllowedGlobal = {
    countries: allowedReqMIReportCountriesGlobal,
    reqPermissions: allowedReqMIReportReqGlobal,
    resPermissions: allowedReqMIReportResGlobal,
    other: allowedReqMIReportOtherGlobal,
    isAllowed: allowedReqMIReportCountriesGlobal && allowedReqMIReportReqGlobal && allowedReqMIReportOtherGlobal,
  };
  // ----- Req MI Report -----

  // ----- Req Participants -----
  const allowedReqParticipantsCountries =
    country === CountryCode.UK || country === CountryCode.BF || country === CountryCode.GLOBAL;
  const allowedReqParticipantsReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("Participant Management") ||
    reqPermissions.includes("ITSM");
  const allowedReqParticipantsRes = false;
  const allowedReqParticipantsOther = !hideRequestService;
  const reqParticipantsAllowed = {
    countries: allowedReqParticipantsCountries,
    reqPermissions: allowedReqParticipantsReq,
    resPermissions: allowedReqParticipantsRes,
    other: allowedReqParticipantsOther,
    isAllowed: allowedReqParticipantsCountries && allowedReqParticipantsReq && allowedReqParticipantsOther,
  };
  // ----- Req Participants -----

  // ----- Res Transaction History -----
  const allowedResTHCountries = !(country === CountryCode.NL || isJpmCountry(country));
  const allowedResTHReq = false;
  const allowedResTHRes =
    resPermissions.includes("Administration") ||
    resPermissions.includes("Transaction History");
  const allowedResTHOther = true;
  const resTHAllowed = {
    countries: allowedResTHCountries,
    reqPermissions: allowedResTHReq,
    resPermissions: allowedResTHRes,
    other: allowedResTHOther,
    isAllowed: allowedResTHCountries && allowedResTHRes && allowedResTHOther,
  };
  // ----- Res Transaction History -----

  // ----- Res Accounts -----
  const allowedResAccountsCountries =
    country === CountryCode.UK ||
    country === CountryCode.BF ||
    country === CountryCode.GLOBAL ||
    country === CountryCode.FR ||
    country === CountryCode.EPC;
  const allowedResAccountsReq = false;
  const allowedResAccountsRes =
    resPermissions.includes("Administration") || resPermissions.includes("Account Management");
  const allowedResAccountsOther = !hideAccounts;
  const resAccountsAllowed = {
    countries: allowedResAccountsCountries,
    reqPermissions: allowedResAccountsReq,
    resPermissions: allowedResAccountsRes,
    other: allowedResAccountsOther,
    isAllowed: allowedResAccountsCountries && allowedResAccountsRes && allowedResAccountsOther,
  };
  // ----- Res Accounts -----

  // ----- Res Settings -----
  const allowedResSettingsCountries =
    country === CountryCode.UK || country === CountryCode.GLOBAL || country === CountryCode.EPC;
  const allowedResSettingsReq = false;
  const allowedResSettingsRes =
    resPermissions.includes("Administration") || resPermissions.includes("Account Management");
  const allowedResSettingsOther = true;
  const resSettingsAllowed = {
    countries: allowedResSettingsCountries,
    reqPermissions: allowedResSettingsReq,
    resPermissions: allowedResSettingsRes,
    other: allowedResSettingsOther,
    isAllowed: allowedResSettingsCountries && allowedResSettingsRes && allowedResSettingsOther,
  };
  // ----- Res Settings -----

  // ----- Res Participants -----
  const allowedResParticipantsCountries =
    country === CountryCode.UK || country === CountryCode.BF || country === CountryCode.GLOBAL;
  const allowedResParticipantsReq = false;
  const allowedResParticipantsRes =
    resPermissions.includes("Administration") ||
    resPermissions.includes("Participant Management") ||
    resPermissions.includes("ITSM");
  const allowedResParticipantsOther = true;
  const resParticipantsAllowed = {
    countries: allowedResParticipantsCountries,
    reqPermissions: allowedResParticipantsReq,
    resPermissions: allowedResParticipantsRes,
    other: allowedResParticipantsOther,
    isAllowed: allowedResParticipantsCountries && allowedResParticipantsRes && allowedResParticipantsOther,
  };
  // ----- Res Participants -----

  // ----- Settings User Management -----
  const allowedSettingsUserCountries = true;
  const allowedSettingsUserReq = reqPermissions.includes("Administration");
  const allowedSettingsUserRes = resPermissions.includes("Administration");
  const allowedSettingsUserOther = true;
  const settingsUserAllowed = {
    countries: allowedSettingsUserCountries,
    reqPermissions: allowedSettingsUserReq,
    resPermissions: allowedSettingsUserRes,
    other: allowedSettingsUserOther,
    isAllowed: allowedSettingsUserReq || allowedSettingsUserRes,
  };
  // ----- Settings User Management -----

  // ----- Settings Performance -----
  const allowedSettingsPerformanceCountries = !(country === CountryCode.BF || country === CountryCode.EPC);
  const allowedSettingsPerformanceReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("Participant Management") ||
    reqPermissions.includes("ITSM");
  const allowedSettingsPerformanceRes =
    resPermissions.includes("Administration") ||
    resPermissions.includes("Participant Management") ||
    resPermissions.includes("ITSM");
  const allowedSettingsPerformanceOther = true;
  const settingsPerformanceAllowed = {
    countries: allowedSettingsPerformanceCountries,
    reqPermissions: allowedSettingsPerformanceReq,
    resPermissions: allowedSettingsPerformanceRes,
    other: allowedSettingsPerformanceOther,
    isAllowed: allowedSettingsPerformanceCountries && (allowedSettingsPerformanceReq || allowedSettingsPerformanceRes),
  };
  // ----- Settings Performance -----

  // ----- Settings Alerts -----
  const allowedSettingsAlertsCountries = !(
    country === CountryCode.BF ||
    country === CountryCode.FR ||
    country === CountryCode.NL ||
    country === CountryCode.EPC ||
    isJpmCountry(country)
  );
  const allowedSettingsAlertsReq = false;
  const allowedSettingsAlertsRes = resPermissions.includes("Administration");
  const allowedSettingsAlertsOther = isAlertEnabled;
  const settingsAlertsAllowed = {
    countries: allowedSettingsAlertsCountries,
    reqPermissions: allowedSettingsAlertsReq,
    resPermissions: allowedSettingsAlertsRes,
    other: allowedSettingsAlertsOther,
    isAllowed: allowedSettingsAlertsCountries && allowedSettingsAlertsRes && allowedSettingsAlertsOther,
  };
  // ----- Settings Alerts -----

  // ----- Settings Participant Cache -----
  const allowedSettingsPCacheCountries = !(
    country === CountryCode.FR ||
    country === CountryCode.NL ||
    isJpmCountry(country)
  );
  const allowedSettingsPCacheReq =
    reqPermissions.includes("Administration") ||
    reqPermissions.includes("Participant Management") ||
    reqPermissions.includes("ITSM");
  const allowedSettingsPCacheRes =
    resPermissions.includes("Administration") || resPermissions.includes("Participant Management");
  const allowedSettingsPCacheOther = !hideParticipantsCache;
  const settingsPCacheAllowed = {
    countries: allowedSettingsPCacheCountries,
    reqPermissions: allowedSettingsPCacheReq,
    resPermissions: allowedSettingsPCacheRes,
    other: allowedSettingsPCacheOther,
    isAllowed:
      allowedSettingsPCacheCountries &&
      (allowedSettingsPCacheReq || allowedSettingsPCacheRes) &&
      allowedSettingsPCacheOther,
  };
  // ----- Settings Participant Cache -----

  return {
    [Routes.DASHBOARD]: dashboardAllowed,

    [Routes.REQUEST_CONFIRM_SINGLE_PAYEE]: requestPayeeAllowed,
    [Routes.REQUEST_CONFIRM_SINGLE_PAYEE_TRANSACTIONS]: requestPayeeAllowed,
    [Routes.REQUEST_CONFIRM_BATCH_PAYEE]: requestPayeeAllowed,

    [Routes.REQUEST_TRANSACTION_HISTORY]: reqTHAllowed,

    [Routes.REQUEST_MI_REPORT]: reqMIReportAllowed,
    [Routes.REQUEST_GLOBAL_MI_REPORT]: reqMIReportAllowedGlobal,
    [Routes.REQUEST_SETTINGS_MI_REPORT]: reqMIReportAllowed,
    [Routes.REQUEST_GLOBAL_MI_REPORT_SETTINGS]: reqMIReportAllowedGlobal,
    [Routes.REQUEST_SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.REQUEST_PARTICIPANTS]: reqParticipantsAllowed,
    [Routes.REQUEST_PARTICIPANTS_MANUAL_DCR]: reqParticipantsAllowed,

    [Routes.REQUEST_CONFIRM_PAYEE]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },
    [Routes.REQUEST]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.RESPONSE_SERVICE_HISTORY]: resTHAllowed,
    [Routes.RESPONSE_SERVICE_ACCOUNTS]: resAccountsAllowed,

    [Routes.RESPONSE_SERVICE_SETTINGS_HONORIFICS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_DESCRIPTORS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_LEVENSHTEIN]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.RESPONSE_SERVICE_PARTICIPANTS]: resParticipantsAllowed,
    [Routes.RESPONSE]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.USER_MANAGEMENT]: settingsUserAllowed,
    [Routes.PERFORMANCE_METRICS]: settingsPerformanceAllowed,
    [Routes.ALERTS]: settingsAlertsAllowed,
    [Routes.PARTICIPANTS_CACHE]: settingsPCacheAllowed,
    [Routes.SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.ADMIN_PROFILE]: {
      countries: true,
      reqPermissions: true,
      resPermissions: true,
      other: true,
      isAllowed: true,
    },
  } as {
    [p: string]: {
      countries: boolean;
      reqPermissions: boolean;
      resPermissions: boolean;
      other: boolean;
      isAllowed: boolean;
    };
  };
};

export const findNearestAllowedRoute = (
  routesWithPermissions: ReturnType<typeof permissionsByPage>,
  subRoute?: string,
) => {
  for (const [route, permission] of Object.entries(routesWithPermissions)) {
    if (permission.isAllowed) {
      if (!subRoute) {
        return route;
      }
      if (subRoute && route.includes(subRoute)) {
        return route;
      }
    }
  }
  return "";
};
